import React from 'react';

import './queryButton.css';

export default function QueryButton({ text, style, type, slug }) {

  if (type === 'submit') {
    return (
      <button type="submit" className={"learn-more tran3s p-color-bg hvr-shutter-out-horizontal-two " + style}>{text}</button>
    )
  } else {
    return (
      <a href={`/market-report-research/${slug}`} className={"learn-more tran3s p-color-bg hvr-shutter-out-horizontal-two " + style}>{text}</a>
    )
  }
}