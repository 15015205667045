import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"

import { post, getCaptcha } from "../../network/apiCalls"

import QueryButton from '../Base/QueryButton/QueryButton';

import './form.css';

export default function Form({ slug, headingText }) {
  slug = slug || '';

  const { register, handleSubmit, errors, reset } = useForm();
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [captchaSiteKey, setCaptchaSiteKey] = useState(null);
  const [showPop, setShowPop] = useState(false);
  const [message, setMessage] = useState('');
  // const captchaSiteKey = `${process.env.CAPTCHA_SITE_KEY}`//Fetch from api

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await getCaptcha();
      setCaptchaSiteKey(response.CAPTCHA_SITE_KEY);
    }
    fetchData();

  }, []);

  const onSubmit = async data => {
    try {
      if (isValidCaptcha) {
        post(data, (flag) => {
          if (flag) {
            reset();
            setMessage('Thanks for email, We will get back to you as soon as possible.')
            setShowPop(true);
          } else {
            setMessage('Oops, something went wrong. We will fix this as soon as possible and get back to you.')
            setShowPop(true);
          }
        })
      } else {
        alert("Please Validate the captcha")
      }
    } catch (error) {
      console.log("errors....", error)
    }
  }

  function changeCaptchaValid(value) {
    setIsValidCaptcha(value)
  }

  function closePopUp() {
    setShowPop(false);
  }

  if (captchaSiteKey === null) {
    return (
      <div>...Loading</div>
    )
  }

  return (
    <div class="container query-form">
      {
        showPop ? <div id="popup1" class="overlay">
          <div class="popup">
            {/* <h2>Here i am</h2> */}
            <a class="close" href="#" onClick={closePopUp}>&times;</a>
            <div class="content query-section">
              <div>{message}</div><br></br>
              <QueryButton text="Go Back" slug={slug.toLowerCase()} type="button" />
            </div>
          </div>
        </div> : null
      }
      <form onSubmit={handleSubmit(onSubmit)} type="POST">
        <h1 className="mb-5">
          Please fill the below form to get the {headingText} for:{" "}
          <span className="query-report-title">{slug
            .replace("-21", "")
            .split("-")
            .join(" ")}
          </span>
        </h1>
        <div className="row">
          <div className="col-sm-6 mb-2">
            <input
              type="text"
              ref={register({
                required: "name"
              })}
              class="form-control mb-2"
              placeholder="Your Name*"
              aria-label="Your Name*"
              id="name"
              name="name"
            />
          </div>
          <div className="col-sm-6 mb-2">
            <input
              type="email"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
              className={`form-control mb-2 ${errors.emailId ? " error" : ""}`}
              placeholder="Your Email Address*"
              aria-label="Your Email Address*"
              id="emailId"
              name="emailId"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 mb-2">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Job Title"
              aria-label="Job Title*"
              ref={register}
              name="jobTitle"
            />
          </div>
          <div className="col-sm-6 mb-2">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Company Name"
              aria-label="Your Name*"
              ref={register}
              name="companyName"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-2">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Mobile Number"
              aria-label="Your Contact Number"
              ref={register}
              name="number"
            />
          </div>
        </div>
        <textarea
          type="textarea"
          className={`form-control mb-2 ${errors.message ? ' error' : ''}`}
          placeholder="Message"
          aria-label="Your Message"
          rows="8"
          ref={register({
            required: "Required"
          })}
          name="message"
        />
        <input type="hidden" ref={register} value="2" id="type" name="type" />
        <input
          type="hidden"
          ref={register}
          value={slug}
          id="report"
          name="report"
        />
        <ReCAPTCHA sitekey={captchaSiteKey} onChange={changeCaptchaValid} />
        <div
          className={`col-md-12 query-section double-grid`}
          style={{ paddingLeft: "0px", marginBottom: "0px", marginTop: "0.5em" }}
        >
          <QueryButton text="Submit" type="submit" />
          <QueryButton text="Cancel" slug={slug.toLowerCase()} type="button" style="maringLeft30" />
        </div>
      </form>
    </div>
  )
}
